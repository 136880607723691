import { stringToTimestamp, formatBigNumber } from "../utils";
import { TagType, Item } from "../types";
import { Value, Opacity } from "../components";
import { escapeRoomsHistory } from "./escapeRoomsHistory";
import { travellingHistory } from "./travellingHistory";

export const items: Item[] = [
    {
        title: "Půst",
        tagTypeList: [TagType.Experiences],
        content: () => (
            <div>
                <Value>
                    40 <Opacity>hodin</Opacity>
                </Value>
            </div>
        ),
        history: [
            {
                title: "17 hodin",
                createdTimestamp: stringToTimestamp("24. 3. 2022"),
            },
            {
                title: "23 hodin",
                createdTimestamp: stringToTimestamp("6. 8. 2022"),
            },
            {
                title: "25 hodin",
                createdTimestamp: stringToTimestamp("27. 7. 2023"),
            },
            {
                title: "40 hodin",
                note: "na vodě a bylinných čajích",
                createdTimestamp: stringToTimestamp("21. 11. 2024"),
            },
        ].sort((a, b) => b.createdTimestamp - a.createdTimestamp),
        createdTimestamp: stringToTimestamp("24. 3. 2022"),
        updatedTimestamp: stringToTimestamp("21. 11. 2024"),
    },
    {
        title: "Outbreak 🧟",
        subtitle: "Střílečka ve virtuální realitě • Zero Latency Praha • První místo",
        tagTypeList: [TagType.Experiences],
        content: () => (
            <div>
                <Value>
                    280 625 <Opacity>bodů</Opacity>
                </Value>
                <p>
                    <a href="https://zerolatencyprague.cz">Web</a>
                </p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("8. 7. 2024"),
    },
    {
        title: "Tandem seskok padákem",
        subtitle: "Egypt • The Mangel Restaurant Rixos",
        tagTypeList: [TagType.Experiences],
        content: () => (
            <div>
                <Value>
                    4 000 metrů
                </Value>
                <p>
                    <a href="https://www.skydivepharaohs.com">Web</a>
                </p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("10. 8. 2024"),
    },
    {
        title: "Únikové hry",
        subtitle: "„Úniková hra je hra, při níž jsou její účastníci uzavřeni v místnosti či místnostech, kde společně plní jednotlivé úkoly, které vedou k vyřešení celého případu. Po zdolání všech nástrah se účastníkům povede samostatně opustit prostory hry – dokončit hru.“",
        tagTypeList: [TagType.Experiences],
        content: () => (
            <div>
                <Value>
                    {escapeRoomsHistory.length} úniků
                </Value>
            </div>
        ),
        history: escapeRoomsHistory.sort((a, b) => b.createdTimestamp - a.createdTimestamp),
        numberedHistory: true,
        createdTimestamp: stringToTimestamp("12. 6. 2018"),
        updatedTimestamp: Math.max(...escapeRoomsHistory.map(historyItem => historyItem.createdTimestamp)),
    },
    {
        title: "Stronghold Crusader",
        subtitle: "Dokončeny všechny Crusader kampaně bez přeskakování • Dokončeno za více než 95 hodin",
        tagTypeList: [TagType.Gaming],
        content: () => (
            <div>
                <Value>
                    80/80 <Opacity>misí</Opacity>
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("28. 4. 2024"),
    },
    {
        title: "Tlak obouruční činky nad hlavu ve stoje",
        subtitle: "Úchop uprostřed vroubkování • Osa ve spodní pozici pod úrovní brady • S bederním pásem",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>3× 65 kg</Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("30. 11. 2023"),
    },
    {
        title: "Last Stand Tournament",
        subtitle: "Divr Labs • Odměna 6 000 Kč za 1. místo • Střílečka ve virtuální realitě",
        tagTypeList: [TagType.Gaming],
        content: () => (
            <div>
                <Value>
                    3× <Opacity>1. místo</Opacity>
                </Value>
                <p>
                    <a href="https://www.divrlabs.com/leaderboards/">Leaderboard</a>
                </p>
                <p>
                    <a href="https://www.divrlabs.com/cs/last-stand">O hře</a>
                </p>
            </div>
        ),
        history: [
            {
                title: "99 097 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("14. 11. 2023"),
            },
            {
                title: "86 186 bodů 🥇",
                note: "Praha",
                createdTimestamp: stringToTimestamp("20. 1. 2024"),
            },
            {
                title: "81 888 bodů 🥇",
                note: "Praha",
                createdTimestamp: stringToTimestamp("30. 10. 2022"),
            },
            {
                title: "78 752 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("4. 3. 2024"),
            },
            {
                title: "76 312 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("4. 3. 2024"),
            },
            {
                title: "69 739 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("20. 1. 2024"),
            },
            {
                title: "62 476 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("29. 4. 202"),
            },
            {
                title: "58 594 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("24. 10. 2023"),
            },
            {
                title: "56 714 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("4. 3. 2024"),
            },
            {
                title: "50 503 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("13. 1. 2024"),
            },
            {
                title: "71 631 bodů 🥇",
                note: "Praha",
                createdTimestamp: stringToTimestamp("17. 8. 2024"),
            },
            {
                title: "57 015 bodů",
                note: "Praha",
                createdTimestamp: stringToTimestamp("17. 8. 2024"),
            },
            {
                title: "82 893 bodů",
                note: "Londýn",
                createdTimestamp: stringToTimestamp("11. 11. 2023"),
            },
            {
                title: "65 119 bodů",
                note: "Londýn",
                createdTimestamp: stringToTimestamp("11. 11. 2023"),
            },
        ].sort((a, b) => b.createdTimestamp - a.createdTimestamp),
        createdTimestamp: stringToTimestamp("30. 10. 2023"),
        updatedTimestamp: stringToTimestamp("17. 8. 2024"),
    },
    {
        title: "Bungee Jumping",
        subtitle: "Bungy Niouc • 190 metrů nad zemí • Švýcarsko",
        tagTypeList: [TagType.Experiences],
        content: () => (
            <div>
                <Value>
                    160 metrů
                </Value>
                <p>
                    <a href="https://www.bungyniouc.com/?lang=en">Web</a>
                </p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("16. 7. 2023"),
    },
    {
        title: "Mistrovství Evropy ve street dance 2023",
        subtitle: "Danceway Praha • Chorvatsko • Kategorie senioři",
        tagTypeList: [TagType.Sport, TagType.Dance],
        content: () => (
            <div>
                <Value>
                    1. místo
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("18. 6. 2023"),
    },
    {
        title: "Cestování",
        subtitle:
            "Slovensko • Tunisko • Španělsko • Dánsko • Nizozemsko • Estonsko • Maďarsko • Sardinie • Mallorca • Španělsko • Francie • Litva • Itálie • Švýcarsko • Rakousko • Ukrajina • Chorvatsko • Slovinsko • Rumunsko • Anglie • Řecko • Německo • Bali • Wales • Egypt",
        tagTypeList: [TagType.Experiences],
        content: () => (
            <div>
                <Value>
                    25 zemí <Opacity>či ostrovů</Opacity>
                </Value>
            </div>
        ),
        history: travellingHistory,
        numberedHistory: true,
        createdTimestamp: stringToTimestamp("1. 7. 2000"),
        updatedTimestamp: Math.max(...travellingHistory.map(historyItem => historyItem.createdTimestamp)),
    },
    {
        title: "Bench Press",
        subtitle: "Soupažný tlak v lehu na rovné lavici s velkou činkou",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>1× 112,5 kg</Value>
                <p>
                    <a href="https://fitness-atom.cz/kontakt/">Atom Fitness Centrum Třebíč</a>
                </p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("3. 5. 2022"),
    },
    {
        title: "Půlmaraton",
        subtitle: "Celkově 30. z 139 (15,54 %) • Muži 18–39 let 11. z 50 (22 %) • Zlepšení od roku 2018 o 9:06",
        tagTypeList: [TagType.Sport, TagType.Running],
        content: () => (
            <div>
                <Value>1:39:00</Value>
                <p>
                    <a href="https://trebicskypulmaraton.cz/">Halahoj Třebíčský půlmaraton</a>
                </p>
            </div>
        ),
        history: [
            {
                title: "1:48:06 (Halahoj Třebíčský půlmaraton 2018)",
                createdTimestamp: stringToTimestamp("21. 4. 2018"),
            },
        ],
        createdTimestamp: stringToTimestamp("21. 4. 2018"),
        updatedTimestamp: stringToTimestamp("30. 4. 2022"),
    },
    {
        title: "Spartan Race Beast",
        subtitle: "67. z 1650 (4 %) • Nastoupáno 1291 m • Ve výšce 865–1386 m n. m.",
        tagTypeList: [TagType.Sport, TagType.Running],
        content: () => (
            <div>
                <Value>22,7 km / 3:27:36</Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("19. 9. 2014"),
    },
    {
        title: "Klik na bradlech",
        subtitle: "Pod paralelní bod • Bradla 67 cm od sebe • Váha bez pásu, řetězu a karabiny • Průřez madel 49 mm",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>3× 60 kg</Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("28. 7. 2022"),
    },
    {
        title: "Shyb na hrazdě neutrálním úchopem",
        subtitle: "Z mrtvého visu • Palcový úchop • Váha bez opasku, řetězu a karabiny • Dotek hrazdy pod klíční kostí • Na hřisti v Tasově",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>2× 30 kg</Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("21. 7. 2022"),
    },
    {
        title: "Zadní dřep",
        subtitle: "High bar • Váha včetně osy • Bez bot • 20 cm nad zem",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>2× 100 kg</Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("29. 8. 2022"),
    },
    {
        title: "Bicepsový zdvih s dvouruční činkou",
        subtitle: "Váha včetně osy • Rovná osa",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>3× 45 kg</Value>
            </div>
        ),
        history: [
            {
                title: "5× 40 kg",
                createdTimestamp: stringToTimestamp("10. 10. 2022"),
            },
            {
                title: "4× 40 kg",
                createdTimestamp: stringToTimestamp("24. 7. 2022"),
            },
        ],
        createdTimestamp: stringToTimestamp("24. 7. 2022"),
        updatedTimestamp: stringToTimestamp("18. 10. 2022"),
    },
    {
        title: "Stažení mobilních aplikace",
        subtitle: `Hrací kostky ${formatBigNumber(36843)} • Závody Prstů ${formatBigNumber(5854)} • Dýchej ${formatBigNumber(4135)} • Série a pauzy ${formatBigNumber(3327)} • Porcovač ${formatBigNumber(753)}`,
        tagTypeList: [TagType.Work],
        content: () => (
            <div>
                <Value>
                    {formatBigNumber(36843 + 5854 + 4135 + 3327 + 753)} <Opacity>stažení</Opacity>
                </Value>
                <a href="https://play.google.com/store/apps/dev?id=7006181994130331856">Google Play</a>
            </div>
        ),
        history: [
            {
                title: `${formatBigNumber(36079 + 5846 + 4106 + 3301 + 752)} stažení`,
                createdTimestamp: stringToTimestamp("28. 4. 2024"),
            },
            {
                title: `${formatBigNumber(35712 + 5844 + 3947 + 3294 + 752)} stažení`,
                createdTimestamp: stringToTimestamp("24. 3. 2024"),
            },
            {
                title: `${formatBigNumber(34677 + 5826 + 3647 + 3221 + 746)} stažení`,
                createdTimestamp: stringToTimestamp("1. 12. 2023"),
            },
            {
                title: `${formatBigNumber(34395 + 5808 + 3629 + 3201 + 743)} stažení`,
                createdTimestamp: stringToTimestamp("3. 9. 2023"),
            },
            {
                title: `${formatBigNumber(34131 + 5773 + 3612 + 3162 + 733)} stažení`,
                createdTimestamp: stringToTimestamp("3. 6. 2023"),
            },
            {
                title: `${formatBigNumber(33341 + 5529 + 2752 + 1501 + 714)} stažení`,
                createdTimestamp: stringToTimestamp("22. 10. 2022"),
            },
            {
                title: `${formatBigNumber(33178 + 5447 + 2562 + 1068 + 707)} stažení`,
                createdTimestamp: stringToTimestamp("1. 9. 2022"),
            },
        ],
        createdTimestamp: stringToTimestamp("1. 9. 2022"),
        updatedTimestamp: stringToTimestamp("19. 8. 2024"),
    },
    {
        title: "Need for Speed: Underground",
        subtitle: "První místo ve všech nelegálních závodech na nejvyšší obtížnost",
        tagTypeList: [TagType.Gaming],
        content: () => (
            <div>
                <Value>
                    112/111 <Opacity>závodů</Opacity>
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("2. 9. 2022"),
    },
    {
        title: "Regionální olympiáda dětí a mládeže 2011",
        subtitle: "Zlato za cyklistiku a plážový volejbal, stříbro za běh, kanoistiku, štafetu 200 m a volejbal • Náměšť nad Oslavou",
        tagTypeList: [TagType.Sport],
        content: () => (
            <div>
                <Value>
                    5. místo
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("21. 5. 2011"),
    },
    {
        title: "Taneční skupina roku 2022",
        subtitle: "T-BASS Hradec Králové • Kategorie senioři",
        tagTypeList: [TagType.Sport, TagType.Dance],
        content: () => (
            <div>
                <Value>
                    2. místo
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("3. 6. 2022"),
    },
    {
        title: "Soutěž Žádný hit dvakrát",
        subtitle: "Výhra v soutěži na rádiu Vysočina",
        tagTypeList: [TagType.Contest],
        content: () => (
            <div>
                <Value>
                    10 000 Kč
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("18. 4. 2012"), // Přibližně
    },
    {
        title: "Soutěž o Samsung Galaxy S9+",
        subtitle: "Výhra 128GB SD karta Samsung",
        tagTypeList: [TagType.Contest],
        content: () => (
            <div>
                <Value>
                    5. místo
                </Value>
                <a href="http://dikys9pluslevouzadni.pavelpo.cz/">Zaslaný projekt</a>
                <br />
                <a href="https://www.svetandroida.cz/kdo-vyhral-3x-samsung-galaxy-s9/">Stránka výsledků soutěže</a>
            </div>
        ),
        createdTimestamp: stringToTimestamp("23. 3. 2018"),
    },
    {
        title: "Koloběh Dalešického pivovaru 2016",
        subtitle: "100km trasa překonaná v 5členném týmu s tím, že 4 jedou na kole a jeden běží se štafetovým kolíkem",
        tagTypeList: [TagType.Sport],
        content: () => (
            <div>
                <Value>
                    15. místo <Opacity>z 21 / 7:20:00</Opacity>
                </Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("21. 5. 2016"),
    },
    {
        title: "Shyb na gymnastických kruzích",
        subtitle: "Z mrtvého visu • Palcový úchop • Váha bez opasku, řetězu a karabiny (1,7 kg) • Dotek kruhy prsních svalů",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>1× 45 kg</Value>
            </div>
        ),
        history: [
            {
                title: "1× 40,3 kg",
                createdTimestamp: stringToTimestamp("20. 11. 2023"),
            },
            {
                title: "3× 25 kg",
                createdTimestamp: stringToTimestamp("26. 11. 2022"),
            },
            {
                title: "5× 20 kg",
                createdTimestamp: stringToTimestamp("22. 10. 2022"),
            },
            {
                title: "4× 20 kg",
                createdTimestamp: stringToTimestamp("18. 10. 2022"),
            },
            {
                title: "3× 20 kg",
                createdTimestamp: stringToTimestamp("18. 7. 2022"),
            },
        ],
        createdTimestamp: stringToTimestamp("18. 7. 2022"),
        updatedTimestamp: stringToTimestamp("1. 12. 2023"),
    },
    {
        title: "Tlak obouruční činky nad hlavu v sedě",
        subtitle: "Úchop uprostřed vroubkování • V Tasově • Osa ve spodní pozici pod úrovní brady",
        tagTypeList: [TagType.Sport, TagType.Strength],
        content: () => (
            <div>
                <Value>10× 40 kg</Value>
            </div>
        ),
        createdTimestamp: stringToTimestamp("21. 10. 2022"),
    },
].sort((a, b) => (b.updatedTimestamp ?? b.createdTimestamp) - (a.updatedTimestamp ?? a.createdTimestamp));
